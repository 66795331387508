<template>
  <div class="req-temp-container">

    <!-- Request Expiry Time card starts here -->
    <vs-card class="mt-6">
      <div slot="header">
        <h3 class="font-normal">Request expiry time</h3>
      </div>
      <vs-row >
        <div class="w-full">
          <vs-row>
            <vs-row>
              <p>
                  Set up how long you would like payment requests to stay open
                  before they expire. You can override this when creating
                  individual request templates.
              </p>
            </vs-row>
            <vs-row>
              <div class="w-half mt-10">
                <div class="vx-col w-half">
                  <vs-row class="mb-10">
                    <div class="mr-10">
                      <vs-slider
                        color="primary"
                        text-fixed=" Days"
                        v-model="paymentExpiry"
                        @change="changeExpiry"
                        max="90"
                      />
                    </div>
                    <vs-input
                      type="number"
                      min="3"
                      max="90"
                      id="paymentExpiry"
                      v-validate="'required|numeric|between:3,90'"
                      class="daysInput col-sm-4"
                      name="paymentExpiry"
                      v-model="paymentExpiry"
                    >
                    </vs-input>
                    <span class="days"> days</span>
                  </vs-row>
                  <span
                    class="text-danger text-sm mb-5"
                    v-show="errors.has('paymentExpiry')"
                    >{{ errors.first("paymentExpiry") }}</span
                  >
                </div>
              </div>
            </vs-row>
          </vs-row>
        </div>
      </vs-row>
      <vs-row class="block">
        <div class="button-section">
          <vs-button
            :disabled="!validateForm"
            class="primary lg:mr-8 mr-4"
            @click="save"
            size="large"
            v-round
            >Save</vs-button
          >
          <div class="mid-blue" @click="onCancel"><u>Cancel</u></div>
        </div>
      </vs-row>
    </vs-card>
     <leave-popup
        :popup-active="popupActive"
        @handleClick="popActions"
      ></leave-popup>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import LeavePopup from "@/views/components/LeavePopup";
import { leavePopUpMixin } from "@/mixins/leave-pop-up";

export default {
  mixins: [leavePopUpMixin],
  components: {LeavePopup},
  data() {
    return {
      paymentExpiry: 0,
      popupActive: false, // determines if the leave popup is to be shown
      nextObj: "", // determines the next route,
      isSaved: false,
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any();
    },
    user() {
      return this.$store.state.AppActiveUser;
    },
    partnerId() {
      return this.user.userType === "admin"
        ? this.user._id
        : this.user.partnerId
    },
  },
  mounted() {
    this.merchantId =
      JSON.parse(localStorage.user).userType == "admin"
        ? JSON.parse(localStorage.user).merchantId
        : JSON.parse(localStorage.user).partner.merchantId;
    this.getPaymentExpiry();
  },
  methods: {
    ...mapActions("merchantGlobalSetting", [
      "fetchSettingsByMerchantId",
      "savePaymentExpiry",
    ]),
    ...mapActions("merchantSettings", ["fetchPartnerDetailById"]),
    getPaymentExpiry() {
      this.fetchSettingsByMerchantId()
        .then((res) => {
          if (res.data.data.paymentExpiry) {
            this.paymentExpiry = res.data.data.paymentExpiry;
          } else {
            this.fetchPartnerDetailById(this.partnerId).then((res) => {
              this.paymentExpiry = res.data.data.paymentExpiry;
            });
          }
        })
        .catch((err) => {
          
          this.fetchPartnerDetailById(this.partnerId).then((res) => {
            this.paymentExpiry = res.data.data.paymentExpiry;
          });
        });
    },
    onCancel() {
      this.$router.push({ name: "settings" });
    },
    async save() {
      try {
        const savedPaymentExpiry = this.savePaymentExpiry({
          paymentExpiry: this.paymentExpiry,
        });
        this.isSaved = true;
        if (savedPaymentExpiry) this.$router.push({ name: "settings" });
      } catch (err) {
        
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    //check here
    if (this.isFormDirty && !this.isSaved) {
      this.nextObj = next;
      this.popupActive = true;
      localStorage.setItem("redirectTo", to.name || undefined);
    } else {
      next();
    }
  },
  watch: {
    isFormDirty(latest, previous) {
      if (latest) localStorage.setItem("formFieldUpdated", "yes");
    },

    isSaved(latest, previous) {
      if (!latest) localStorage.setItem("formFieldUpdated", "yes");
    }
  }
};
</script>